import React from 'react';
import {Navbar} from 'react-bootstrap';
import {Link} from 'react-router-dom';

function AppComponent() {
    return (
        <div>
            <header>
                <Navbar className="navbar navbar-light navbar-expand-lg fixed-top" id="mainNav">
                    <div className="container">
                        <Link to="/" className="navbar-brand js-scroll-trigger sokoboxes-title">Sokoboxes</Link>
                        <div className="collapse navbar-collapse" id="navbarResponsive">
                            <ul className="nav navbar-nav ml-auto">
                                <li className="nav-item" role="presentation">
                                    <Link to="/" className="nav-link js-scroll-trigger">Home</Link>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <Link to="/info" className="nav-link js-scroll-trigger">Info</Link>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <Link to="/articles" className="nav-link js-scroll-trigger">Articles</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Navbar>
            </header>

            <footer>
                <div className="container">
                    <p>&copy; 2020-2025 Carlos Montiers Aguilera. All Rights Reserved.</p>
                </div>
            </footer>
        </div>
    );
}

export default AppComponent;
