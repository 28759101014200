import React from "react";

import img_msx_magazine_1986_08_page22_sokoban3_announce
    from "../../../assets/img/msx_magazine_1986_08_page22_sokoban3_announce.png";

import img_sokoban_me_splash from "../../../assets/img/sokoban_me_splash.png";
import img_sokoban_me_menu from "../../../assets/img/sokoban_me_menu.png";
import img_sokoban_me_ingame_06 from "../../../assets/img/sokoban_me_ingame_06.png";
import img_sokoban_me_ingame_15 from "../../../assets/img/sokoban_me_ingame_15.png";
import img_sokoban_me_ingame_21 from "../../../assets/img/sokoban_me_ingame_21.png";

import img_sokoban_first_steps_square_enix_splash from "../../../assets/img/sokoban_first_steps_square_enix_splash.png";
import img_sokoban_first_steps_square_enix_001 from "../../../assets/img/sokoban_first_steps_square_enix_001.png";
import img_sokoban_first_steps_square_enix_001_menu
    from "../../../assets/img/sokoban_first_steps_square_enix_001_menu.png";
import img_sokoban_first_steps_square_enix_002 from "../../../assets/img/sokoban_first_steps_square_enix_002.png";
import img_sokoban_first_steps_square_enix_ingame from "../../../assets/img/sokoban_first_steps_square_enix_ingame.png";

import img_sokoban_perfect_1_square_enix_splash from "../../../assets/img/sokoban_perfect_1_square_enix_splash.png";
import img_sokoban_perfect_1_square_enix_menu from "../../../assets/img/sokoban_perfect_1_square_enix_menu.png";
import img_sokoban_perfect_1_square_enix_001 from "../../../assets/img/sokoban_perfect_1_square_enix_001.png";
import img_sokoban_perfect_1_square_enix_002 from "../../../assets/img/sokoban_perfect_1_square_enix_002.png";
import img_sokoban_perfect_1_square_enix_003 from "../../../assets/img/sokoban_perfect_1_square_enix_003.png";

import img_sokoban_perfect_2_square_enix_splash from "../../../assets/img/sokoban_perfect_2_square_enix_splash.png";
import img_sokoban_perfect_2_square_enix_001 from "../../../assets/img/sokoban_perfect_2_square_enix_001.png";

import img_sokoban_perfect_3_square_enix_splash from "../../../assets/img/sokoban_perfect_3_square_enix_splash.png";
import img_sokoban_perfect_3_square_enix_001 from "../../../assets/img/sokoban_perfect_3_square_enix_001.png";

import img_sokoban_dx_first_steps_and_perfect_1_square_enix_splash
    from "../../../assets/img/sokoban_dx_first_steps_and_perfect_1_square_enix_splash.png";
import img_sokoban_dx_first_steps_and_perfect_1_square_enix_001
    from "../../../assets/img/sokoban_dx_first_steps_and_perfect_1_square_enix_001.png";

import img_sokoban_first_steps_dwango_splash from "../../../assets/img/sokoban_first_steps_dwango_splash.png";
import img_sokoban_first_steps_dwango_ingame from "../../../assets/img/sokoban_first_steps_dwango_ingame.png";

import img_sokoban_perfect_1_1_dwango_splash from "../../../assets/img/sokoban_perfect_1_1_dwango_splash.png";
import img_sokoban_perfect_1_1_dwango_ingame from "../../../assets/img/sokoban_perfect_1_1_dwango_ingame.png";
import img_sokoban_perfect_1_2_dwango_splash from "../../../assets/img/sokoban_perfect_1_2_dwango_splash.png";
import img_sokoban_perfect_1_2_dwango_ingame from "../../../assets/img/sokoban_perfect_1_2_dwango_ingame.png";
import img_sokoban_perfect_1_3_dwango_splash from "../../../assets/img/sokoban_perfect_1_3_dwango_splash.png";
import img_sokoban_perfect_1_3_dwango_ingame from "../../../assets/img/sokoban_perfect_1_3_dwango_ingame.png";

import img_sokoban_perfect_2_1_dwango_splash from "../../../assets/img/sokoban_perfect_2_1_dwango_splash.png";
import img_sokoban_perfect_2_1_dwango_ingame from "../../../assets/img/sokoban_perfect_2_1_dwango_ingame.png";
import img_sokoban_perfect_2_2_dwango_splash from "../../../assets/img/sokoban_perfect_2_2_dwango_splash.png";
import img_sokoban_perfect_2_2_dwango_ingame from "../../../assets/img/sokoban_perfect_2_2_dwango_ingame.png";
import img_sokoban_perfect_2_3_dwango_splash from "../../../assets/img/sokoban_perfect_2_3_dwango_splash.png";
import img_sokoban_perfect_2_3_dwango_ingame from "../../../assets/img/sokoban_perfect_2_3_dwango_ingame.png";

import img_sokoban_perfect_3_1_dwango_splash from "../../../assets/img/sokoban_perfect_3_1_dwango_splash.png";
import img_sokoban_perfect_3_1_dwango_ingame from "../../../assets/img/sokoban_perfect_3_1_dwango_ingame.png";
import img_sokoban_perfect_3_2_dwango_splash from "../../../assets/img/sokoban_perfect_3_2_dwango_splash.png";
import img_sokoban_perfect_3_2_dwango_ingame from "../../../assets/img/sokoban_perfect_3_2_dwango_ingame.png";
import img_sokoban_perfect_3_3_dwango_splash from "../../../assets/img/sokoban_perfect_3_3_dwango_splash.png";
import img_sokoban_perfect_3_3_dwango_ingame from "../../../assets/img/sokoban_perfect_3_3_dwango_ingame.png";

import img_sokoban_konami_wai_wai_sokoban_splash from "../../../assets/img/konami_wai_wai_sokoban_splash.png";
import img_konami_wai_wai_sokoban_ingame_01 from "../../../assets/img/konami_wai_wai_sokoban_ingame_01.png";
import img_konami_wai_wai_sokoban_ingame_02 from "../../../assets/img/konami_wai_wai_sokoban_ingame_02.png";
import img_konami_wai_wai_sokoban_ingame_03 from "../../../assets/img/konami_wai_wai_sokoban_ingame_03.png";

import img_sokoban_plus_e_ingame from "../../../assets/img/sokoban_plus_e_ingame.png";

export default function ArticleTheLostArchivesOfSokoban() {
    return (
        <>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-12 rounded bg-white">
                            <h3 className="title mb-0">
                                The Lost Archives of Sokoban
                            </h3>
                            <p className="text-right">
                                <sub>Published: 2025 March 14</sub>
                            </p>
                            <p>
                                Over the years, while collecting Sokoban games, I’ve come across a few mysterious
                                titles. Some were announced but appear to have never been released; others were once
                                available but are now lost, and some might never have existed at all. To describe these
                                cases, I’ve learned three terms: <em>vaporware</em>, <em>lost media</em>,
                                and <em>rumored titles</em>.
                            </p>

                            <h4>Vaporware</h4>
                            <p>Vaporware refers to games that were announced but never officially released.</p>
                            <p>
                                One example of vaporware I consider is <strong><em>倉庫番3 (Sokoban 3)</em></strong>.
                                This
                                game, featuring 130 puzzles for the PC-8801, was announced in the Japanese
                                magazines <em>MSX Magazine</em> (August 1986, page <a className="text-primary"
                                                                                      href="https://archive.org/details/msx-magazine-1986-08-ascii-jp_2/MSX%20Magazine%20%281986-08%29%20%28ASCII%29%20%28JP%29%20/page/n21/mode/2up"
                                                                                      target="_blank"
                                                                                      rel="noopener noreferrer">22</a>), <em>Micom
                                BASIC</em> (October 1986, page <a className="text-primary"
                                                                  href="https://archive.org/details/micomBASIC-1986-10/page/n19/mode/2up"
                                                                  target="_blank"
                                                                  rel="noopener noreferrer">20</a>), and <em>LOGiN
                                Magazine</em> (October 1986, page <a className="text-primary"
                                                                     href="https://archive.org/details/logi-n-october-1986-raw-scans/LOGiN%20-%20October%201986/page/n39/mode/2up"
                                                                     target="_blank"
                                                                     rel="noopener noreferrer">40</a>), with plans to
                                distribute it on a floppy disk via Takeru vending machines.
                            </p>
                            <p>
                                Strangely, nobody seems to remember <em>Sokoban 3</em>, and no evidence has surfaced
                                confirming that it was ever sold on those machines.
                            </p>
                            <p className="text-center">
                                <img
                                    className="img-fluid"
                                    src={img_msx_magazine_1986_08_page22_sokoban3_announce}
                                    alt=""
                                />
                            </p>
                            <p>
                                <em>Sokoban 3</em> was also mentioned in the book <em>The Sokoban</em> ("THE 倉庫番")
                                in 1987, which listed all its puzzles on pages 134 and 135. However, it does not appear
                                in the list of released games on page 112.
                            </p>
                            <p>
                                According to my conversation with Mr. Hiroyuki, <em>Sokoban 3</em> was the product name
                                that eventually evolved into <em>Sokoban Perfect</em>.
                            </p>
                            <p>
                                It’s still possible that the game was sold, even if only for a single day, and someone
                                might have bought it. Perhaps a copy still exists, tucked away in a Japanese home
                                library or even a developer’s archive. The picture in the magazine suggests the game was
                                at least partially developed, leaving hope that a copy may still be found someday.
                            </p>

                            <h4>Rumored title</h4>
                            <p>
                                A rumored title refers to a game (or other media) that has been mentioned, discussed, or
                                speculated about but lacks concrete evidence of its existence.
                            </p>
                            <p>
                                One such case, in my opinion, is <strong><em>倉庫番Revenge (Sokoban
                                Revenge)</em> for the PC-8801</strong>. As of now, it only appears on the Sokoban
                                History page, but no pictures, screenshots, or gameplay footage have been found.
                            </p>
                            <p>
                                This is quite different from <em>Sokoban Perfect</em> for the PC-8801, which has
                                verifiable proof of its existence.
                            </p>

                            <h4>Lost Media</h4>
                            <p>
                                Lost media includes games that were once available but are now nearly
                                impossible to find.
                            </p>
                            <p>
                                Many Sokoban games—if not all—created for Japanese mobile phones between 2004 and
                                2007 fall into this category.
                            </p>
                            <ul>
                                <li>
                                    <p className="mb-1">
                                        <strong><em>Sokoban M.E.</em></strong>, published by Living Mobile GmbH in
                                        2005. It featured 48 puzzles.<br/>
                                        Most websites that hosted reviews of it have vanished. Some pages mentioned it
                                        was a JAR file of 96 KB.
                                    </p>
                                    <p className="text-center">
                                        <img
                                            className="img-fluid mt-3 mr-3"
                                            src={img_sokoban_me_splash}
                                            alt=""
                                        />
                                        <img
                                            className="img-fluid mt-3 mr-3"
                                            src={img_sokoban_me_menu}
                                            alt=""
                                        />
                                        <img
                                            className="img-fluid mt-3 mr-3"
                                            src={img_sokoban_me_ingame_06}
                                            alt=""
                                        />
                                        <img
                                            className="img-fluid mt-3 mr-3"
                                            src={img_sokoban_me_ingame_15}
                                            alt=""
                                        />
                                        <img
                                            className="img-fluid mt-3 mr-3"
                                            src={img_sokoban_me_ingame_21}
                                            alt=""
                                        />
                                    </p>
                                    <p>
                                        This game was also reviewed in the German magazine <em>Bravo
                                        Screenfun</em> (November 2005, page <a className="text-primary"
                                                                               href="https://archive.org/details/screenfun/2005_11/page/102/mode/1up"
                                                                               target="_blank"
                                                                               rel="noopener noreferrer">102</a>).
                                    </p>
                                    <p>
                                        <a className="text-primary"
                                           href="https://web.archive.org/web/20060502020947/http://airgamer.de/cms/front_content.php?idart=1777"
                                           target="_blank" rel="noopener noreferrer">Archived url 1</a>
                                        <br/>
                                        <a className="text-primary"
                                           href="https://web.archive.org/web/20090405080047/http://livingmobile.net/lang/en/cdid/override/index.php?defn=game&category=8&corporate=&corporate_corporate=&corporate_corporate_corporate=&country=2&game=101&go=&go_start=0&mobile_manufacturer=&mobile_manufacturer_model=&is_app=0&sidebar_game=1&sidebar_app=&sidebar_game_toggle=&sidebar_guide=1&sidebar_newsletter=1&sidebar_search=&"
                                           target="_blank" rel="noopener noreferrer">Archived url 2</a>
                                    </p>
                                </li>
                                <li>
                                    <p className="mb-1">
                                        <strong><em>倉庫番 ファーストステップ (Sokoban First Steps)</em></strong>,
                                        published by Dwango in 2004. It featured 48 puzzles.
                                    </p>
                                    <p className="text-center">
                                        <img
                                            className="img-fluid mt-3 mr-3"
                                            src={img_sokoban_first_steps_dwango_splash}
                                            alt=""
                                        />
                                        <img
                                            className="img-fluid mt-3 mr-3"
                                            src={img_sokoban_first_steps_dwango_ingame}
                                            alt=""
                                        />
                                    </p>
                                    <p>
                                        <a className="text-primary"
                                           href="https://web.archive.org/web/20070323061435/http://contents.dwango.jp/games/category/souko.html"
                                           target="_blank" rel="noopener noreferrer">Archived url</a>
                                    </p>
                                </li>

                                <li>
                                    <p className="mb-1">
                                        <strong><em>倉庫番 パーフェクト(1-1) (Sokoban Perfect (1-1))</em></strong>,
                                        published by Dwango in 2005. It featured 16 puzzles.
                                    </p>
                                    <p className="text-center">
                                        <img
                                            className="img-fluid mt-3 mr-3"
                                            src={img_sokoban_perfect_1_1_dwango_splash}
                                            alt=""
                                        />
                                        <img
                                            className="img-fluid mt-3 mr-3"
                                            src={img_sokoban_perfect_1_1_dwango_ingame}
                                            alt=""
                                        />
                                    </p>
                                    <p>
                                        <a className="text-primary"
                                           href="https://web.archive.org/web/20070323061435/http://contents.dwango.jp/games/category/souko.html"
                                           target="_blank" rel="noopener noreferrer">Archived url</a>
                                    </p>
                                </li>
                                <li>
                                    <p className="mb-1">
                                        <strong><em>倉庫番 パーフェクト(1-2) (Sokoban Perfect (1-2))</em></strong>,
                                        published by Dwango in 2005. It featured 16 puzzles.
                                    </p>
                                    <p className="text-center">
                                        <img
                                            className="img-fluid mt-3 mr-3"
                                            src={img_sokoban_perfect_1_2_dwango_splash}
                                            alt=""
                                        />
                                        <img
                                            className="img-fluid mt-3 mr-3"
                                            src={img_sokoban_perfect_1_2_dwango_ingame}
                                            alt=""
                                        />
                                    </p>
                                    <p>
                                        <a className="text-primary"
                                           href="https://web.archive.org/web/20070323061435/http://contents.dwango.jp/games/category/souko.html"
                                           target="_blank" rel="noopener noreferrer">Archived url</a>
                                    </p>
                                </li>
                                <li>
                                    <p className="mb-1">
                                        <strong><em>倉庫番 パーフェクト(1-3) (Sokoban Perfect (1-3))</em></strong>,
                                        published by Dwango in 2005. It featured 16 puzzles.
                                    </p>
                                    <p className="text-center">
                                        <img
                                            className="img-fluid mt-3 mr-3"
                                            src={img_sokoban_perfect_1_3_dwango_splash}
                                            alt=""
                                        />
                                        <img
                                            className="img-fluid mt-3 mr-3"
                                            src={img_sokoban_perfect_1_3_dwango_ingame}
                                            alt=""
                                        />
                                    </p>
                                    <p>
                                        <a className="text-primary"
                                           href="https://web.archive.org/web/20070323061435/http://contents.dwango.jp/games/category/souko.html"
                                           target="_blank" rel="noopener noreferrer">Archived url</a>
                                    </p>
                                </li>

                                <li>
                                    <p className="mb-1">
                                        <strong><em>倉庫番 パーフェクト(2-1) (Sokoban Perfect (2-1))</em></strong>,
                                        published by Dwango in 2005. It featured 16 puzzles.
                                    </p>
                                    <p className="text-center">
                                        <img
                                            className="img-fluid mt-3 mr-3"
                                            src={img_sokoban_perfect_2_1_dwango_splash}
                                            alt=""
                                        />
                                        <img
                                            className="img-fluid mt-3 mr-3"
                                            src={img_sokoban_perfect_2_1_dwango_ingame}
                                            alt=""
                                        />
                                    </p>
                                    <p>
                                        <a className="text-primary"
                                           href="https://web.archive.org/web/20070323061435/http://contents.dwango.jp/games/category/souko.html"
                                           target="_blank" rel="noopener noreferrer">Archived url</a>
                                    </p>
                                </li>
                                <li>
                                    <p className="mb-1">
                                        <strong><em>倉庫番 パーフェクト(2-2) (Sokoban Perfect (2-2))</em></strong>,
                                        published by Dwango in 2005. It featured 16 puzzles.
                                    </p>
                                    <p className="text-center">
                                        <img
                                            className="img-fluid mt-3 mr-3"
                                            src={img_sokoban_perfect_2_2_dwango_splash}
                                            alt=""
                                        />
                                        <img
                                            className="img-fluid mt-3 mr-3"
                                            src={img_sokoban_perfect_2_2_dwango_ingame}
                                            alt=""
                                        />
                                    </p>
                                    <p>
                                        <a className="text-primary"
                                           href="https://web.archive.org/web/20070323061435/http://contents.dwango.jp/games/category/souko.html"
                                           target="_blank" rel="noopener noreferrer">Archived url</a>
                                    </p>
                                </li>
                                <li>
                                    <p className="mb-1">
                                        <strong><em>倉庫番 パーフェクト(2-3) (Sokoban Perfect (2-3))</em></strong>,
                                        published by Dwango in 2005. It featured 16 puzzles.
                                    </p>
                                    <p className="text-center">
                                        <img
                                            className="img-fluid mt-3 mr-3"
                                            src={img_sokoban_perfect_2_3_dwango_splash}
                                            alt=""
                                        />
                                        <img
                                            className="img-fluid mt-3 mr-3"
                                            src={img_sokoban_perfect_2_3_dwango_ingame}
                                            alt=""
                                        />
                                    </p>
                                    <p>
                                        <a className="text-primary"
                                           href="https://web.archive.org/web/20070323061435/http://contents.dwango.jp/games/category/souko.html"
                                           target="_blank" rel="noopener noreferrer">Archived url</a>
                                    </p>
                                </li>

                                <li>
                                    <p className="mb-1">
                                        <strong><em>倉庫番 パーフェクト(3-1) (Sokoban Perfect (3-1))</em></strong>,
                                        published by Dwango in 2005. It featured 16 puzzles.
                                    </p>
                                    <p className="text-center">
                                        <img
                                            className="img-fluid mt-3 mr-3"
                                            src={img_sokoban_perfect_3_1_dwango_splash}
                                            alt=""
                                        />
                                        <img
                                            className="img-fluid mt-3 mr-3"
                                            src={img_sokoban_perfect_3_1_dwango_ingame}
                                            alt=""
                                        />
                                    </p>
                                    <p>
                                        <a className="text-primary"
                                           href="https://web.archive.org/web/20070323061435/http://contents.dwango.jp/games/category/souko.html"
                                           target="_blank" rel="noopener noreferrer">Archived url</a>
                                    </p>
                                </li>
                                <li>
                                    <p className="mb-1">
                                        <strong><em>倉庫番 パーフェクト(3-2) (Sokoban Perfect (3-2))</em></strong>,
                                        published by Dwango in 2005. It featured 16 puzzles.
                                    </p>
                                    <p className="text-center">
                                        <img
                                            className="img-fluid mt-3 mr-3"
                                            src={img_sokoban_perfect_3_2_dwango_splash}
                                            alt=""
                                        />
                                        <img
                                            className="img-fluid mt-3 mr-3"
                                            src={img_sokoban_perfect_3_2_dwango_ingame}
                                            alt=""
                                        />
                                    </p>
                                    <p>
                                        <a className="text-primary"
                                           href="https://web.archive.org/web/20070323061435/http://contents.dwango.jp/games/category/souko.html"
                                           target="_blank" rel="noopener noreferrer">Archived url</a>
                                    </p>
                                </li>
                                <li>
                                    <p className="mb-1">
                                        <strong><em>倉庫番 パーフェクト(3-3) (Sokoban Perfect (3-3))</em></strong>,
                                        published by Dwango in 2005. It featured 16 puzzles.
                                    </p>
                                    <p className="text-center">
                                        <img
                                            className="img-fluid mt-3 mr-3"
                                            src={img_sokoban_perfect_3_3_dwango_splash}
                                            alt=""
                                        />
                                        <img
                                            className="img-fluid mt-3 mr-3"
                                            src={img_sokoban_perfect_3_3_dwango_ingame}
                                            alt=""
                                        />
                                    </p>
                                    <p>
                                        <a className="text-primary"
                                           href="https://web.archive.org/web/20070323061435/http://contents.dwango.jp/games/category/souko.html"
                                           target="_blank" rel="noopener noreferrer">Archived url</a>
                                    </p>
                                </li>

                                <li>
                                    <p className="mb-1">
                                        <strong><em>倉庫番 ファーストステップ (Sokoban First Steps)</em></strong>,
                                        published by Square Enix in 2004. It featured 48 puzzles.
                                    </p>
                                    <p className="text-center">
                                        <img
                                            className="img-fluid mt-3 mr-3"
                                            src={img_sokoban_first_steps_square_enix_splash}
                                            alt=""
                                        />
                                        <img
                                            className="img-fluid mt-3 mr-3"
                                            src={img_sokoban_first_steps_square_enix_001}
                                            alt=""
                                        />
                                        <img
                                            className="img-fluid mt-3 mr-3"
                                            src={img_sokoban_first_steps_square_enix_001_menu}
                                            alt=""
                                        />
                                        <img
                                            className="img-fluid mt-3 mr-3"
                                            src={img_sokoban_first_steps_square_enix_002}
                                            alt=""
                                        />
                                        <img
                                            className="img-fluid mt-3 mr-3"
                                            src={img_sokoban_first_steps_square_enix_ingame}
                                            alt=""
                                        />
                                    </p>
                                    <p>
                                        <a className="text-primary"
                                           href="https://web.archive.org/web/20231111180451/https://nlab.itmedia.co.jp/games/gsnews/0402/02/news07.html"
                                           target="_blank" rel="noopener noreferrer">Archived url 1</a>
                                        <br/>
                                        <a className="text-primary"
                                           href="https://web.archive.org/web/20051214034301/http://www.square-enix.co.jp/mobile/game/pocket/pocket_puzzle.html"
                                           target="_blank" rel="noopener noreferrer">Archived url 2</a>
                                    </p>
                                </li>
                                <li>
                                    <p className="mb-1">
                                        <strong><em>倉庫番 パーフェクト(1) (Sokoban Perfect (1))</em></strong>,
                                        published by Square Enix in 2004. It featured 48 puzzles.
                                    </p>
                                    <p className="text-center">
                                        <img
                                            className="img-fluid mt-3 mr-3"
                                            src={img_sokoban_perfect_1_square_enix_splash}
                                            alt=""
                                        />
                                        <img
                                            className="img-fluid mt-3 mr-3"
                                            src={img_sokoban_perfect_1_square_enix_menu}
                                            alt=""
                                        />
                                        <img
                                            className="img-fluid mt-3 mr-3"
                                            src={img_sokoban_perfect_1_square_enix_001}
                                            alt=""
                                        />
                                        <img
                                            className="img-fluid mt-3 mr-3"
                                            src={img_sokoban_perfect_1_square_enix_002}
                                            alt=""
                                        />
                                        <img
                                            className="img-fluid mt-3 mr-3"
                                            src={img_sokoban_perfect_1_square_enix_003}
                                            alt=""
                                        />
                                    </p>
                                    <p>
                                        <a className="text-primary"
                                           href="https://web.archive.org/web/20130628054707/http://game.watch.impress.co.jp/docs/20040513/sqenpp.htm"
                                           target="_blank" rel="noopener noreferrer">Archived url 1</a>
                                        <br/>
                                        <a className="text-primary"
                                           href="https://web.archive.org/web/20051214034301/http://www.square-enix.co.jp/mobile/game/pocket/pocket_puzzle.html"
                                           target="_blank" rel="noopener noreferrer">Archived url 2</a>
                                    </p>
                                </li>
                                <li>
                                    <p className="mb-1">
                                        <strong><em>倉庫番 パーフェクト(2) (Sokoban Perfect (2))</em></strong>,
                                        published by Square Enix in 2004. It featured 48 puzzles.
                                    </p>
                                    <p className="text-center">
                                        <img
                                            className="img-fluid mt-3 mr-3"
                                            src={img_sokoban_perfect_2_square_enix_splash}
                                            alt=""
                                        />
                                        <img
                                            className="img-fluid mt-3 mr-3"
                                            src={img_sokoban_perfect_2_square_enix_001}
                                            alt=""
                                        />
                                    </p>
                                    <p>
                                        <a className="text-primary"
                                           href="https://web.archive.org/web/20051214034301/http://www.square-enix.co.jp/mobile/game/pocket/pocket_puzzle.html"
                                           target="_blank" rel="noopener noreferrer">Archived url</a>
                                    </p>
                                </li>
                                <li>
                                    <p className="mb-1">
                                        <strong><em>倉庫番 パーフェクト(3) (Sokoban Perfect (3))</em></strong>,
                                        published by Square Enix in 2004. It featured 48 puzzles.
                                    </p>
                                    <p className="text-center">
                                        <img
                                            className="img-fluid mt-3 mr-3"
                                            src={img_sokoban_perfect_3_square_enix_splash}
                                            alt=""
                                        />
                                        <img
                                            className="img-fluid mt-3 mr-3"
                                            src={img_sokoban_perfect_3_square_enix_001}
                                            alt=""
                                        />
                                    </p>
                                    <p>
                                        <a className="text-primary"
                                           href="https://web.archive.org/web/20051214034301/http://www.square-enix.co.jp/mobile/game/pocket/pocket_puzzle.html"
                                           target="_blank" rel="noopener noreferrer">Archived url</a>
                                    </p>
                                </li>
                                <li>
                                    <p className="mb-1">
                                        <strong><em>倉庫番 DX ファーストステップ&パーフェクト(1) (Sokoban DX First Steps
                                            & Perfect (1))</em></strong>,
                                        published by Square Enix in 2004. It featured 96 puzzles.
                                    </p>
                                    <p className="text-center">
                                        <img
                                            className="img-fluid mt-3 mr-3"
                                            src={img_sokoban_dx_first_steps_and_perfect_1_square_enix_splash}
                                            alt=""
                                        />
                                        <img
                                            className="img-fluid mt-3 mr-3"
                                            src={img_sokoban_dx_first_steps_and_perfect_1_square_enix_001}
                                            alt=""
                                        />
                                    </p>
                                    <p>
                                        <a className="text-primary"
                                           href="https://web.archive.org/web/20051214034301/http://www.square-enix.co.jp/mobile/game/pocket/pocket_puzzle.html"
                                           target="_blank" rel="noopener noreferrer">Archived url</a>
                                    </p>
                                </li>

                                <li>
                                    <p className="mb-1">
                                        <strong><em>コナミワイワイ倉庫番 (Konami Wai Wai Sokoban)</em></strong>,
                                        published by Konami in 2007.
                                    </p>
                                    <p className="text-center">
                                        <img
                                            className="img-fluid mt-3 mr-3"
                                            src={img_sokoban_konami_wai_wai_sokoban_splash}
                                            alt=""
                                        />
                                        <img
                                            className="img-fluid mt-3 mr-3"
                                            src={img_konami_wai_wai_sokoban_ingame_01}
                                            alt=""
                                        />
                                        <img
                                            className="img-fluid mt-3 mr-3"
                                            src={img_konami_wai_wai_sokoban_ingame_02}
                                            alt=""
                                        />
                                        <img
                                            className="img-fluid mt-3 mr-3"
                                            src={img_konami_wai_wai_sokoban_ingame_03}
                                            alt=""
                                        />
                                    </p>
                                    <p>
                                        <a className="text-primary"
                                           href="https://web.archive.org/web/20130608121826/http://www.gpara.com/article/cms_show.php?c_id=2867&c_num=14"
                                           target="_blank" rel="noopener noreferrer">Archived url 1</a>
                                        <br/>
                                        <a className="text-primary"
                                           href="https://web.archive.org/web/20250227115015/https://www.vgmuseum.com/mrp/cameo5.htm#kws"
                                           target="_blank" rel="noopener noreferrer">Archived url 2</a>
                                    </p>
                                </li>
                            </ul>

                            <p>
                                Another interesting lost Sokoban game was created in 2005 for the PLUS e (プラスe), a
                                touchscreen entertainment device mainly found at tables in casual restaurants.
                            </p>
                            <ul>
                                <li>
                                    <p className="mb-1">
                                        <strong><em>倉庫番 プラスe (Sokoban Plus e)</em></strong>,
                                        published by JCM in 2004.
                                    </p>
                                    <p className="text-center">
                                        <img
                                            className="img-fluid mt-3 mr-3"
                                            src={img_sokoban_plus_e_ingame}
                                            alt=""
                                        />
                                    </p>
                                    <p>
                                        <a className="text-primary"
                                           href="https://web.archive.org/web/20050503121833/http://www.hoget.co.jp/contents/product.htm"
                                           target="_blank" rel="noopener noreferrer">Archived url</a>
                                    </p>
                                </li>
                            </ul>

                            <h4>The Search Continues</h4>
                            <p>
                                As a Sokoban player, I’m always on the lookout for unique puzzles that may have
                                been lost to time. While some of these puzzles might have reappeared in later releases,
                                that’s only part of what I’m searching for. Sokoban isn’t just about solving
                                puzzles—it’s about experiencing each game as a whole. The original graphics, sounds, and
                                atmosphere created a distinct mood that can’t be replicated. Still, I remain hopeful
                                that one day, these lost games will be found, allowing us to fully appreciate the
                                creativity and charm of their time.
                            </p>
                            <p>
                                If you have any information or resources that might help uncover these forgotten
                                games, please feel free to contact me at <a className="text-primary"
                                                                            href="mailto:cmontiers@gmail.com">cmontiers@gmail.com</a>.
                            </p>
                            <p>
                                A special thank you to Mr. Joseph Redon, who kindly shared his insights
                                and helped me reach the conclusions presented here. His knowledge and passion for
                                Japanese games have been invaluable to this research.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
