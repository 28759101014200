import React from "react";

import img_sokoban_puzzle_cmontiers from "../../../assets/img/sokoban_puzzle_cmontiers.png";
import img_sokoboxes_duo_logo from "../../../assets/img/sokoboxes_duo_logo.png";

export default function HomePage() {
    return (
        <section>
            <div className="container rounded bg-white">
                <div className="row">
                    <div className="col-12">
                        <h3>Welcome</h3>
                        <p>Sokoboxes is a site dedicated to Sokoban (倉庫番).</p>
                        <p>It is an amazing puzzle video game.</p>
                        <p>
                            The goal of the player is to push boxes around in a warehouse
                            until all of them are on storage locations.
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-center mt-1 mb-2">
                        <p>I created a new game based on Sokoban (click to play)</p>
                        <a href="https://sokoboxes.com/duo">
                            <img src={img_sokoboxes_duo_logo} alt="Sokoboxes Duo"/>
                        </a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-center">
                        <p>
                            I became fascinated by the game in 2009. In 2011, I created this
                            level to show the gameplay in the {" "}
                            <a
                                className="text-primary"
                                href="https://en.wikipedia.org/wiki/Sokoban"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Wikipedia article
                            </a>
                            .
                        </p>
                        <img
                            className="img mb-3"
                            src={img_sokoban_puzzle_cmontiers}
                            alt="A Sokoban puzzle"
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}
