import {InterfaceGameItem} from "../interfaces/interface-game-item";

import img_puk_puk_msx_001 from "../../assets/screenshots/puk_puk_msx_001.png";
import img_puk_puk_msx_title from "../../assets/screenshots/puk_puk_msx_title.png";

export const boxPushingSimilarGames: Array<InterfaceGameItem> = [
    {
        title: "Puk Puk (MSX)",
        year: "1986 February",
        publisher: "POPCOM",
        images: [
            img_puk_puk_msx_001,
            img_puk_puk_msx_title
        ],
        comment: "Push boxes to open a path and collect stars."
    },
];
