import img_sokomania_nintendo_dsi_episode2_002 from "../../assets/screenshots/sokomania_nintendo_dsi_episode2_002.png";
import img_sokomania_nintendo_dsi_title from "../../assets/screenshots/sokomania_nintendo_dsi_title.png";

import img_sokomania2_nintendo_dsi_003 from "../../assets/screenshots/sokomania2_nintendo_dsi_003.png";
import img_sokomania2_nintendo_dsi_title from "../../assets/screenshots/sokomania2_nintendo_dsi_title.png";

import {InterfaceGameItem} from "../interfaces/interface-game-item";

export const sokobanVariantsGames: Array<InterfaceGameItem> = [
    {
        title: "Sokomania (Nintendo DSi)",
        year: "2010",
        publisher: "Cinemax",
        images: [img_sokomania_nintendo_dsi_episode2_002, img_sokomania_nintendo_dsi_title],
    },
    {
        title: "Sokomania 2: Cool Job (Nintendo DSi)",
        year: "2014",
        publisher: "Cinemax",
        images: [img_sokomania2_nintendo_dsi_003, img_sokomania2_nintendo_dsi_title],
    },
];
